import React from 'react'
import { Container } from 'react-bootstrap'

const CopyRight = () => (
  <>
    <div className="copy-right">
      <Container className="h-100">
        <div className="copy-right_inner h-100">
          <div style={{ fontSize: '12px' }}>
            This website is not affiliated with any company and is only a hobby project.
            All trademarks are the property of their respective owners. If you have any questions, please contact us via contact form.
          </div>
        </div>
      </Container>
    </div>
  </>
)

export default CopyRight
