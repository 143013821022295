import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { Container } from 'react-bootstrap'

const Footer = () => (
  <>
    <div className="footer">
      <Container className="h-100">
        <div className="footer_inner h-100">
          <div className="footer_logo zx-brightness-120 zx-transform-scale-11 zx-transition-03">
            <Image width={180} height={100} src='/images/logo.svg' alt="Logo" />
          </div>
          <div className="footer_sections">
            <div className="footer_section">
              <div className="footer_section_header">
                <h3 className="footer_section_header_title">Resources</h3>
              </div>
              <div className="footer_section_content">
                <ul className="footer_section_content_list">
                <li className="footer_section_content_list_item">
                    <Link href="/blog/wow-repacks" className="footer_section_content_list_item_link">
                      Wow Repacks
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/blog/wow-private-server-clients" className="footer_section_content_list_item_link">
                      WoW Clients
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/auth/register" className="footer_section_content_list_item_link">
                      Add your server
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/server-news" className="footer_section_content_list_item_link">
                      Add server news
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <a href="https://discord.gg/S6yffquAkA" target="_blank" rel="noopener noreferrer" className="footer_section_content_list_item_link">
                      Our Discord
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_section">
              <div className="footer_section_header">
                <h3 className="footer_section_header_title">SERVER TYPES</h3>
              </div>
              <div className="footer_section_content">
                <ul className="footer_section_content_list">
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers" className="footer_section_content_list_item_link">
                      All Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/pve-private-servers" className="footer_section_content_list_item_link">
                      PvE Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/pvp-private-servers" className="footer_section_content_list_item_link">
                      PvP Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/rp-private-servers" className="footer_section_content_list_item_link" shallow={false}>
                      RP Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/custom-private-servers" className="footer_section_content_list_item_link" shallow={false}>
                      Custom Servers
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_section">
              <div className="footer_section_header">
                <h3 className="footer_section_header_title">Servers</h3>
              </div>
              <div className="footer_section_content">
                <ul className="footer_section_content_list">
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/vanilla-private-servers" className="footer_section_content_list_item_link">
                      Vanilla Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/tbc-private-servers" className="footer_section_content_list_item_link">
                      TBC Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/wotlk-private-servers" className="footer_section_content_list_item_link">
                      WOTLK Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/cataclysm-private-servers" className="footer_section_content_list_item_link">
                      Cataclysm Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/mop-private-servers" className="footer_section_content_list_item_link">
                      MOP Servers
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_section">
              <div className="footer_section_header">
                <h3 className="footer_section_header_title">SERVERS</h3>
              </div>
              <div className="footer_section_content">
                <ul className="footer_section_content_list">
                <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/wod-private-servers" className="footer_section_content_list_item_link">
                      WoD Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/legion-private-servers" className="footer_section_content_list_item_link">
                      Legion Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/bfa-private-servers" className="footer_section_content_list_item_link">
                      BFA Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/shadowlands-private-servers" className="footer_section_content_list_item_link">
                      Shadowlands Servers
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/wow/private-servers/dragonflight-private-servers" className="footer_section_content_list_item_link">
                      Dragonflight Servers
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_section">
              <div className="footer_section_header">
                <h3 className="footer_section_header_title">Zremax</h3>
              </div>
              <div className="footer_section_content">
                <ul className="footer_section_content_list">
                  <li className="footer_section_content_list_item">
                  <Link href="/contact-us" className="footer_section_content_list_item_link">
                      Contact us
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                  <Link href="/partners" className="footer_section_content_list_item_link">
                      Partners
                    </Link>
                  </li>
                  {/* <li className="footer_section_content_list_item">
                    <Link href="/advertising" className="footer_section_content_list_item_link">
                      Server Advertisements
                    </Link>
                  </li> */}
                  <li className="footer_section_content_list_item">
                    <Link href="/privacy" className="footer_section_content_list_item_link">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="footer_section_content_list_item">
                    <Link href="/terms-and-conditions" className="footer_section_content_list_item_link">
                      Terms of Service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </>
)

export default Footer
