import React from 'react'
import MenuItem from './MenuItem'
import MenuItemDropdown from './MenuItemDropdown'
import { privateServerMenu, wowSection, addonMenu } from './MenuLinksData'
//  <MenuItemDropdown title="WoW Private Servers" accordion={true} url="#" menu={privateServerMenu} />
const Menu = () => {
  return (
  <>
  <div className="menu">
    <MenuItemDropdown title="WoW Servers" accordion={true} url="/wow/private-servers" menu={privateServerMenu} multiLevel={false} />
    <MenuItemDropdown title="WoW Addons" accordion={true} url="/wow/addons" menu={addonMenu} multiLevel={false} />
    <MenuItemDropdown title="World of Warcraft" accordion={true} url="#" menu={wowSection} multiLevel={true} />
    <MenuItem title="Blog" url="/blog" />
    {/* <MenuItem title="Advertising" url="/advertising" /> */}
  </div>
  </>
  )
}

export default Menu
